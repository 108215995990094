import { Helmet } from 'react-helmet'

import {
  Header,
  If,
  MobileHeader
} from '@itau-loans-www/shopping/src/components'
import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import classnames from 'classnames'

import MainLayout from '../Main'
import './summary.scss'

export default ({
  children,
  className,
  isHeaderColorBrand = false,
  hasHeader = true,
  ...props
}) => {
  const classNamesLayout = classnames('summary-layout', {
    [className]: className
  })

  const classNamesContent = classnames('summary-content', {
    [className]: className
  })

  const isDesktop = useMediaQuery('desktop')
  const HeaderSummary = () => (
    <If
      condition={isDesktop && hasHeader}
      renderIf={<Header />}
      renderElse={<MobileHeader isBrandColor={isHeaderColorBrand} />}
    />
  )

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div {...props} className={classNamesLayout}>
        <MainLayout Header={HeaderSummary} hasFooter currentPage="result">
          <main className={classNamesContent}>
            <div className="image-wrapper" />

            {children}
          </main>
        </MainLayout>
      </div>
    </>
  )
}
