import { IdsMainButton, IdsIcon } from '@ids/react'
import { Loader } from '@itau-loans-www/shopping/src/components'
import SummaryLayout from '@itau-loans-www/shopping/src/layouts/Summary'

import './Allow-fgts-app.scss'
import AllowSteps from './components/AllowSteps'
import sliderData from './constants'
import { useAllowFgts } from './hooks'

const AllowFGTSApp = () => {
  const { loading, alreadyAuthorized, knowMore, carouselConfig } =
    useAllowFgts()

  return (
    <SummaryLayout className="allow-app-fgts">
      <Loader visible={loading} />
      <div className="container">
        <div className="allow-app-fgts__header">
          <h1 className="allow-app-fgts__title">
            Falta pouco! <br /> Precisamos que você ative o Saque-Aniversário e
            autorize o Itaú no App FGTS Caixa
          </h1>
        </div>

        <AllowSteps sliderData={sliderData} carouselConfig={carouselConfig} />
        <div className="allow-app-fgts__buttons">
          <IdsMainButton
            id="info-btn"
            data-testid="info-btn"
            variant="secondary"
            onClick={knowMore}
          >
            Acessar o app FGTS
            <IdsIcon size="L">link_externo_outline</IdsIcon>
          </IdsMainButton>
          <IdsMainButton
            id="authorize-btn"
            data-testid="authorize-btn"
            onClick={alreadyAuthorized}
          >
            Já autorizei
            <IdsIcon size="L">seta_direita</IdsIcon>
          </IdsMainButton>
        </div>
      </div>
    </SummaryLayout>
  )
}

export default AllowFGTSApp
