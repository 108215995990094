export default [
  {
    img: 'FGTS-passo-1',
    title: 'Baixe o app do FGTS',
    text: 'Utilize sua senha para entrar ou cadastre uma senha no primeiro acesso'
  },
  {
    img: 'FGTS-passo-2',
    title: 'Altere a forma de saque do FGTS',
    text: <>Na página inicial, clique em <strong>Sistemática de saque do seu FGTS.</strong> Leia e aceite os termos e condições e clique em <strong>Optar pelo Saque-Aniversário</strong></>
  },
  {
    img: 'FGTS-passo-3',
    title: 'Autorize a consulta às suas informações',
    text: <>Acesse o menu <strong>Mais</strong> da página inicial e clique em <strong>Autorização de consulta às informações do FGTS</strong> e, depois, em <strong>Empréstimo Saque-Aniversário</strong></>
  },
  {
    img: 'FGTS-passo-4',
    title: 'Compartilhe suas informações',
    text: <>Busque por <strong>Banco Itaú Consignado S.A.</strong> no campo de pesquisa e confirme a autorização de consulta ao seu saldo FGTS. <br /> <strong> Pronto! Agora você pode voltar no site do Itaú e continuar a simulação</strong></>
  }
]
