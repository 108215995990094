import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'
import { storage } from 'utils'

const postFgtsAllowed = async (requestBody = {}) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'fgts-allowed',
    method: 'post',
    body: requestBody
  })

  const token = {
    accessToken: data.auth['token'],
    startDate: data.auth['server_time'],
    validTime: data.auth['valid_time'],
    leadId: data.id
  }

  storage.session.setItem('token', JSON.stringify(token))
  return data?.next_step
}

export default { postFgtsAllowed }
