import { Carousel, CarouselItem } from '@mobi/ds'

import Img from 'components/Img'

import { SLIDER_CONFIG } from './constants'
import './allow-steps.scss'

const AllowSteps = ({ carouselConfig = SLIDER_CONFIG, sliderData }) => {
  return (
    <section className="allow-steps">
      <div className="allow-steps__carousel-wrapper">
        <Carousel
          indentifier="allow-steps"
          variant="slider"
          bullets
          arrows
          transition
          options={carouselConfig}
          className="allow-steps__carousel"
        >
          {sliderData.map(({ img, title, text }, index) => (
            <CarouselItem key={title}>
              <div className="allow-steps__step">
                <div className="allow-steps__header">
                  <Img file={img} className="allow-steps__img" />
                </div>
                <div className="allow-steps__info">
                  <p className="allow-steps__title">{`0${index + 1}. ${title}`}</p>
                  <p className="allow-steps__text">{text}</p>
                </div>
              </div>
            </CarouselItem>
          ))}
        </Carousel>
      </div>
    </section>
  )
}

export default AllowSteps
